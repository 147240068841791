<template>
  <div class="dashboard-board-list">
    <m-ratio-box class="ratio-box" :ratio="65.6" v-for="(item, index) in boardList" :key="index">
      <div class="board-item" :style="{'font-size': fontSize + 'px'}">
        <div class="board-item-inner">
          <div class="label">{{ item.label }}</div>
          <div class="value">
            <span class="valuePrice" v-if="isMoney(item.valueProp)">{{ moneyText }}
            </span>
            <count-to v-if="item.valueProp === 'request' || item.valueProp === 'impress' || item.valueProp === 'click'" :start-val="boardData[item.valueProp]" :decimals="0" :end-val="boardData[item.valueProp]" />
            <count-to v-else :start-val="boardData[item.valueProp]" :decimals="2" :end-val="boardData[item.valueProp]" />
            <span v-if="isRota(item.valueProp)">%</span>
          </div>
          <div class="ratio">
            <div class="icon">
              <component
                class="ratio-icon"
                :is="+boardData[item.ratioProp] >= 0 ? 'RightTop' : 'RightBottom'"
                :style="{ color: +boardData[item.ratioProp] >= 0 ? '#FF8A8A' : '#64C8BC' }"
              ></component>
            </div>
            <div
              class="ratio-value"
              :style="{ color: +boardData[item.ratioProp] >= 0 ? '#FF8A8A' : '#64C8BC' }"
            >
              {{ boardData[item.ratioProp] || 0 }}%
            </div>
          </div>
        </div>
      </div>
    </m-ratio-box>
  </div>
</template>

<script>
import { boardList } from './boardData'
import CountTo from 'vue-count-to'
import GoldCoin from '@/assets/icons/goldCoin.svg?inline'
import RightTop from '@/assets/icons/rightTop.svg?inline'
import RightBottom from '@/assets/icons/rightBottom.svg?inline'
import { mapState } from 'vuex'
import { todayData } from '@/apiForManage/dashboard'
import autoFontSize from '@/mixins/autoFontSize'
import mixRateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
export default {
  components: { GoldCoin, CountTo, RightTop, RightBottom },
  mixins: [autoFontSize, mixRateAndMoneyMethod],
  data () {
    return {
      boardList,
      boardData: {}
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      adminUserId: (state) => state.user.adminUserId
    })
  },
  created () {
    this.getRealTimeData()
  },
  methods: {
    async getRealTimeData () {
      const resp = await todayData()
      if (resp.code === 200) this.boardData = resp.data
    }
  }
}
</script>
<style lang="less" scoped>
.dashboard-board-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: PingFang SC, PingFang SC;

  .board-item {
    height: 100%;
    .board-item-inner {
      height: 100%;
      padding: calc(16em / 14), 0;
      border-radius: @mediumRadius;
      background-color: #fff;
      box-shadow: @modelShadow;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 2px 6px 0 rgba(21, 34, 50, 0.3);
      }
      .coin-icon {
        height: calc(28em / 14);
        width: calc(28em / 14);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          height: calc(18em / 14);
          width: calc(18em / 14);
        }
      }
      .value {
        display: flex;
        font-size: calc(18em / 14);
        margin-bottom: calc(8em / 14);
        color: #344563;
        font-weight: 600;
        .valuePrice {
          font-size: calc(12em / 14)!important;
        }
      }
      .label {
        font-size: calc(14em / 14);
        margin-bottom: calc(4em / 14);
        font-weight: 400;
        color: #5A607F;
      }
      .ratio {
        height: calc(20em / 14);
        display: flex;
        justify-content: center;
        align-items: center;

        .ratio-icon {
          height: calc(16em / 14);
          width: calc(16em / 14);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .ratio-value {
          font-size: calc(14em / 14);
          font-weight: 500;
          margin-left: calc(4em / 14);
        }
        .no-ratio{
          width: calc(30em / 14);
          height: calc(3em / 14);
          margin-top: calc(3em / 14);
          background-color: #f99600;
          border-radius: calc(3em / 14);
        }
      }
    }
  }
  .ratio-box {
    width: calc(13.9% - 7.5em / 14);
  }
}
</style>
